import { useCallback, useReducer, useEffect } from "react"
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  Switch,
  Dimensions,
  TouchableOpacity,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Input from "../../components/Input"
import Btn from "../../components/Btn"
import { useSelector } from "react-redux"
import AwesomeAlert from "react-native-awesome-alerts"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"
import Picker from "../../components/Picker"
import { separate } from "../../components/Functions"
import * as Clipboard from "expo-clipboard"

const windowWidth = Dimensions.get("window").width

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_SERVICES = "SET_SERVICES"
const INVOICE_EXTRA_ITEMS_PRICE = "INVOICE_EXTRA_ITEMS_PRICE"
const SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT"
const SET_NEW_INVOICE = "SET_NEW_INVOICE"
const SET_PROJECT = "SET_PROJECT"
const PROJECT_INVOICE_ITEM_PRICE = "PROJECT_INVOICE_ITEM_PRICE"
const SET_ADD_TO_PROJECT = "SET_ADD_TO_PROJECT"
const EVENT_BLUR = "EVENT_BLUR"
const SET_PAY_LINK = "SET_PAY_LINK"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      if (action.id == "service_category") {
        return {
          ...state,
          values: {
            ...state.values,
            invoice_main_item: "",
            invoice_extra_items: "",
            [action.id]: action.value,
            changed: true,
          },
          validities: {
            ...state.validities,
            [action.id]: action.validity,
          },
        }
      }
      if (action.id == "invoice_main_item_price") {
        let newInvoice_main_item = state.values.invoice_main_item
        let invoice_main_item_price = 0
        if (newInvoice_main_item.price == "0") {
          newInvoice_main_item["invoicePrice"] = 0
          invoice_main_item_price = 0
        } else {
          newInvoice_main_item["invoicePrice"] = parseInt(action.value)
          invoice_main_item_price = parseInt(action.value)
        }
        return {
          ...state,
          values: {
            ...state.values,
            ["invoice_main_item"]: newInvoice_main_item,
            ["invoice_main_item_price"]: invoice_main_item_price,
            changed: true,
          },
          validities: {
            ...state.validities,
            [action.id]: action.validity,
          },
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }
    case INVOICE_EXTRA_ITEMS_PRICE:
      let newInvoice_extra_items = state.values.invoice_extra_items
      for (let i = 0; i < newInvoice_extra_items.length; i++) {
        if (newInvoice_extra_items[i].id == action.secondId) {
          newInvoice_extra_items[i]["invoicePrice"] = parseInt(action.value)
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          ["invoice_extra_items"]: newInvoice_extra_items,
          changed: true,
        },
        validities: {
          ...state.validities,
        },
      }
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertMessage: action.msg,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SERVICES:
      let sepratedServices = {
        site: [],
        extraSite: [],
        graphic: [],
        extraGraphic: [],
        sales: [],
        extraSales: [],
      }

      for (let i = 0; i < action.data.length; i++) {
        action.data[i]["item"] = action.data[i]["title"]
        delete action.data[i]["title"]
        if (action.data[i].category == "site" && action.data[i].type == "primary") {
          sepratedServices.site.push(action.data[i])
        }
        if (action.data[i].category == "site" && action.data[i].type == "extra") {
          sepratedServices.extraSite.push(action.data[i])
        }
        if (action.data[i].category == "graphic" && action.data[i].type == "primary") {
          sepratedServices.graphic.push(action.data[i])
        }
        if (action.data[i].category == "graphic" && action.data[i].type == "extra") {
          sepratedServices.extraGraphic.push(action.data[i])
        }
        if (action.data[i].category == "sales" && action.data[i].type == "primary") {
          sepratedServices.sales.push(action.data[i])
        }
        if (action.data[i].category == "sales" && action.data[i].type == "extra") {
          sepratedServices.extraSales.push(action.data[i])
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          services: sepratedServices,
        },
      }
    case SET_TOTAL_AMOUNT:
      return {
        ...state,
        values: {
          ...state.values,
          total_invoice_price: action.value,
          changed: false,
        },
      }
    case SET_NEW_INVOICE:
      return {
        ...state,
        values: {
          ...state.values,
          service_category: "",
          invoice_main_item: "",
          invoice_extra_items: [],
          total_invoice_price: 0,
          changed: true,
          project: null,
          new_invoice: state.values.new_invoice ? false : true,
          add_to_project: false,
          project_id: null,
        },
      }
    case SET_ADD_TO_PROJECT:
      return {
        ...state,
        values: {
          ...state.values,
          add_to_project: state.values.add_to_project ? false : true,
        },
      }
    case SET_PROJECT:
      return {
        ...state,
        values: {
          ...state.values,
          project: action.data,
        },
      }
    case PROJECT_INVOICE_ITEM_PRICE:
      let updatedProject = state.values.project
      for (let i = 0; i < updatedProject.project_items.length; i++) {
        if (updatedProject.project_items[i].service_id == action.secondId) {
          updatedProject.project_items[i]["project_invoice_price"] = action.value
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          project: updatedProject,
        },
      }
    case EVENT_BLUR:
      return {
        values: {
          loading: false,
          services: {},
          service_category: "",
          invoice_main_item: "",
          invoice_extra_items: [],
          total_invoice_price: 0,
          changed: true,
          new_invoice: true,
          project_id: null,
          project: null,
          add_to_project: false,
          customer_mobile: null,
          pay_link: false,
        },
        validities: {
          title: true,
          price: true,
          category: true,
          type: true,
        },
      }
    case SET_PAY_LINK:
      return {
        ...state,
        values: {
          ...state.values,
          pay_link: action.data,
        },
      }
    default:
      return state
  }
}

const MakeInvoiceScreen = (props) => {
  const DESKTOP = windowWidth > 800 ? true : false
  let project_id = false
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      services: {},
      service_category: "",
      invoice_main_item: "",
      invoice_extra_items: [],
      total_invoice_price: 0,
      changed: true,
      new_invoice: project_id ? false : true,
      project_id: null,
      project: null,
      add_to_project: false,
      customer_mobile: null,
      pay_link: false,
    },
    validities: {
      title: true,
      price: true,
      category: true,
      type: true,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity, secondId) => {
      if (id == "invoice_extra_items_price") {
        dispatchFormState({ type: INVOICE_EXTRA_ITEMS_PRICE, id, value, validity, secondId })
      }
      if (id == "project_item_invoice_price") {
        dispatchFormState({ type: PROJECT_INVOICE_ITEM_PRICE, id, value, validity, secondId })
      } else {
        dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
      }
    },
    [dispatchFormState]
  )

  const formValidity = (form) => {
    const areTrue = Object.values(form.validities).every((value) => value != false)
    return areTrue
  }

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  const create_invoice_items = () => {
    let invoice_items = []
    if (formState.values.new_invoice && !project_id) {
      invoice_items.push(formState.values.invoice_main_item)
      if (formState.values.invoice_extra_items.length != 0) {
        invoice_items = invoice_items.concat(formState.values.invoice_extra_items)
      }
    } else {
      invoice_items = invoice_items.concat(formState.values.project.project_items)
    }
    return invoice_items
  }

  const create_add_to_project_invoice_items = () => {
    let invoice_items = []
    if (formState.values.invoice_extra_items.length != 0) {
      invoice_items = invoice_items.concat(formState.values.invoice_extra_items)
    }
    return invoice_items
  }

  const make_invoice = async (data) => {
    const url = Config.aioUrl + "/invoice/make"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    let reqData = null
    if (formState.values.new_invoice && !project_id) {
      reqData = JSON.stringify({
        customer_mobile: formState.values.customer_mobile,
        customer_firstname: formState.values.customer_firstname,
        customer_lastname: formState.values.customer_lastname,
        invoice_items: create_invoice_items(),
      })
    } else {
      reqData = JSON.stringify({
        project_id: formState.values.project_id,
        invoice_items: create_invoice_items(),
        add_to_project_items: create_add_to_project_invoice_items(),
      })
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    dispatchFormState({ type: SET_PAY_LINK, data: false })
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })

        if (response.data.success) {
          dispatchFormState({ type: SET_PAY_LINK, data: response.data.pay_link })
        } else {
          alert(response.data.msg)
        }
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_PAY_LINK, data: false })
        alert(error)
      })
  }

  const get_service = async () => {
    const url = Config.aioUrl + "/service/get"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_SERVICES, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message })
      })
  }

  const get_project = async (id = false) => {
    let project_id = id ? id : formState.values.project_id
    const url = Config.aioUrl + "/project/get?id=" + project_id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 403) {
          alert(response.data.msg)
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_PROJECT, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message })
      })
  }

  const get_extra_item_price = (id) => {
    if (
      formState.values.service_category.id == "site" ||
      (formState.values.project
        ? formState.values.project.department == "site"
          ? true
          : false
        : false)
    ) {
      for (let i = 0; i < formState.values.services.extraSite.length; i++) {
        if (id == formState.values.services.extraSite[i].id) {
          return parseInt(formState.values.services.extraSite[i].price)
        }
      }
    }
    if (
      formState.values.service_category.id == "graphic" ||
      (formState.values.project
        ? formState.values.project.department == "graphic"
          ? true
          : false
        : false)
    ) {
      for (let i = 0; i < formState.values.services.extraGraphic.length; i++) {
        if (id == formState.values.services.extraGraphic[i].id) {
          return parseInt(formState.values.services.extraGraphic[i].price)
        }
      }
    }
    if (
      formState.values.service_category.id == "sales" ||
      (formState.values.project
        ? formState.values.project.department == "sales"
          ? true
          : false
        : false)
    ) {
      for (let i = 0; i < formState.values.services.extraSales.length; i++) {
        if (id == formState.values.services.extraSales[i].id) {
          return parseInt(formState.values.services.extraSales[i].price)
        }
      }
    }
  }

  const calculate_total_amount = () => {
    let total_invoice_price = 0
    if (formState.values.new_invoice && !project_id) {
      if (formState.values.invoice_main_item.price != "0") {
        total_invoice_price += formState.values.invoice_main_item.invoicePrice
      }
      for (let i = 0; i < formState.values.invoice_extra_items.length; i++) {
        total_invoice_price += formState.values.invoice_extra_items[i].invoicePrice
      }
    } else {
      for (let i = 0; i < formState.values.project.project_items.length; i++) {
        if (formState.values.project.project_items[i].project_invoice_price == undefined) {
          continue
        }
        total_invoice_price += parseInt(
          formState.values.project.project_items[i].project_invoice_price
        )
      }
      for (let i = 0; i < formState.values.invoice_extra_items.length; i++) {
        total_invoice_price += formState.values.invoice_extra_items[i].invoicePrice
      }
    }
    dispatchFormState({ type: SET_TOTAL_AMOUNT, value: total_invoice_price })
  }

  if (props.route.params.project_id != false) {
    project_id = props.route.params.project_id
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_service()
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    get_service()
  }, [formState.values.service_category])

  useEffect(() => {
    get_project(project_id)
  }, [project_id])

  useEffect(() => {
    const blured = props.navigation.addListener("blur", () => {
      dispatchFormState({ type: EVENT_BLUR })
    })

    return blured
  }, [props.navigation])
  console.log(formState.values.customer_mobile)
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        flexDirection: DESKTOP ? "row-reverse" : "column",
      }}
    >
      <View style={{ flex: 1 }}>
        <Header title="ساخت صورتحساب" reset="MakeInvoice" />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ paddingBottom: 50, paddingHorizontal: DESKTOP ? "30%" : 0 }}
        >
          {!project_id && (
            <View
              style={{
                width: "100%",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
                پروژه جدید
              </Text>
              <Switch
                trackColor={{ false: "#767577", true: "#767577" }}
                thumbColor={formState.values.new_invoice ? Colors.primary : Colors.primary}
                onValueChange={() => {
                  dispatchFormState({ type: SET_NEW_INVOICE })
                }}
                value={formState.values.new_invoice}
                style={{ marginHorizontal: 10 }}
              />
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
                پروژه در جریان
              </Text>
            </View>
          )}
          {formState.values.new_invoice && !project_id && !formState.values.pay_link && (
            <View>
              <Input
                label="نام مشتری"
                id="customer_firstname"
                onChange={inputChangeHandler}
                validity
              />
              <Input
                label="نام خانوادگی مشتری"
                id="customer_lastname"
                onChange={inputChangeHandler}
                validity
              />
              <Input
                label="شماره موبایل مشتری"
                id="customer_mobile"
                onChange={inputChangeHandler}
                validity
                onlyNumber
                convertDigits
                mobile
              />
            </View>
          )}
          {(!formState.values.new_invoice || project_id) && (
            <View>
              <Input
                label="کد پروژه"
                id="project_id"
                onChange={inputChangeHandler}
                validity
                insert={project_id}
                convertDigits
              />
            </View>
          )}
          {formState.values.project && (
            <View
              style={{
                width: "100%",
                paddingVertical: 20,
                paddingHorizontal: 20,
                backgroundColor: Colors.secondText,
                borderRadius: 15,
                flexDirection: "row-reverse",
                marginBottom: 30,
              }}
            >
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    marginBottom: 15,
                  }}
                >
                  {formState.values.project.customer_first_name +
                    " " +
                    formState.values.project.customer_last_name}
                </Text>
                <Text style={{ color: "#fff", fontFamily: "IranSans-Regular", fontSize: 16 }}>
                  {formState.values.project.customer_mobile}
                </Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-start" }}>
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    marginBottom: 15,
                  }}
                >
                  {separate(formState.values.project.cost) +
                    " / " +
                    separate(formState.values.project.paid)}
                </Text>
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    direction: "rtl",
                  }}
                >
                  {"مانده: " +
                    separate(formState.values.project.cost - formState.values.project.paid)}
                </Text>
              </View>
            </View>
          )}
          {formState.values.project_id &&
            !formState.values.new_invoice &&
            !formState.values.project && (
              <View>
                <Btn
                  title="دریافت پروژه"
                  onPress={() => {
                    get_project()
                  }}
                  loading={formState.values.loading}
                  color={Colors.blue}
                />
              </View>
            )}
          {formState.values.new_invoice && !project_id && !formState.values.pay_link && (
            <Picker
              label="دسته خدمات"
              id="service_category"
              onChange={inputChangeHandler}
              options={[
                { item: "سایت", id: "site" },
                { item: "گرافیک", id: "graphic" },
                { item: "فروش", id: "sales" },
              ]}
            />
          )}
          {!formState.values.loading &&
            formState.values.service_category != "" &&
            !formState.values.pay_link && (
              <Picker
                label="آیتم اصلی صورتحساب"
                id="invoice_main_item"
                onChange={inputChangeHandler}
                options={
                  formState.values.service_category.id == "site"
                    ? formState.values.services.site
                    : formState.values.service_category.id == "graphic"
                    ? formState.values.services.graphic
                    : formState.values.services.sales
                }
              />
            )}

          {!formState.values.loading &&
            formState.values.service_category != "" &&
            formState.values.invoice_main_item != "" &&
            !formState.values.pay_link && (
              <Picker
                label="آیتم های جانبی صورتحساب"
                id="invoice_extra_items"
                onChange={inputChangeHandler}
                options={
                  formState.values.service_category.id == "site"
                    ? formState.values.services.extraSite
                    : formState.values.service_category.id == "graphic"
                    ? formState.values.services.extraGraphic
                    : formState.values.services.extraSales
                }
                isMulti
              />
            )}
          {!formState.values.loading &&
            formState.values.service_category != "" &&
            formState.values.invoice_main_item != "" &&
            !formState.values.pay_link && (
              <View style={{ flexDirection: "row-reverse", marginTop: 30 }}>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ fontFamily: "IranSans-Regular", color: Colors.text }}>
                    {formState.values.invoice_main_item.item}
                  </Text>
                </View>
                {formState.values.invoice_main_item.price != 0 && (
                  <View style={{ flex: 1 }}>
                    <Input
                      label="مبلغ"
                      id="invoice_main_item_price"
                      onChange={inputChangeHandler}
                      insert={
                        !project_id && !formState.values.project
                          ? parseInt(formState.values.invoice_main_item.price) / 2
                          : () => {
                              for (
                                let i = 0;
                                i < formState.values.project.project_items.length;
                                i++
                              ) {
                                if (
                                  formState.values.project.project_items[i].service_type ==
                                  "primary"
                                )
                                  return (
                                    parseInt(
                                      formState.values.project.project_items[i].service_price
                                    ) -
                                    parseInt(formState.values.project.project_items[i].paid_amount)
                                  )
                              }
                            }
                      }
                      convertDigits
                      onlyNumber
                      maxValue={() => {
                        for (let i = 0; i < formState.values.project.project_items.length; i++) {
                          if (formState.values.project.project_items[i].service_type == "primary")
                            return (
                              parseInt(formState.values.project.project_items[i].service_price) -
                              parseInt(formState.values.project.project_items[i].paid_amount)
                            )
                        }
                      }}
                    />
                  </View>
                )}
              </View>
            )}
          {!formState.values.loading &&
            formState.values.service_category != "" &&
            formState.values.invoice_main_item != "" &&
            formState.values.invoice_extra_items.length != 0 &&
            !formState.values.pay_link && (
              <View>
                {formState.values.invoice_extra_items.map((item, index) => (
                  <View style={{ flexDirection: "row-reverse" }} key={item.id}>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Text style={{ fontFamily: "IranSans-Regular", color: Colors.text }}>
                        {item.item}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Input
                        label="مبلغ"
                        id={"invoice_extra_items_price"}
                        secondId={item.id}
                        onChange={inputChangeHandler}
                        insert={get_extra_item_price(item.id) / 2}
                        convertDigits
                        onlyNumber
                        maxValue={parseInt(item.service_price) - parseInt(item.paid_amount)}
                      />
                    </View>
                  </View>
                ))}
              </View>
            )}
          {formState.values.project &&
            formState.values.project.cost - formState.values.project.paid == 0 && (
              <View
                style={{
                  width: "100%",
                  height: 100,
                  borderRadius: 15,
                  backgroundColor: Colors.green,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ fontFamily: "IranSans-Regular", color: "#fff", fontSize: 16 }}>
                  این پروژه تسویه شده است
                </Text>
              </View>
            )}
          {formState.values.project &&
            formState.values.project.cost - formState.values.project.paid != 0 &&
            !formState.values.pay_link && (
              <View>
                {formState.values.project.project_items.map((item, index) => (
                  <View style={{ flexDirection: "row-reverse" }} key={item.service_id}>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Text style={{ fontFamily: "IranSans-Regular", color: Colors.text }}>
                        {item.service_title}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      {parseInt(item.service_price) != parseInt(item.paid_amount) && (
                        <Input
                          label="مبلغ"
                          id="project_item_invoice_price"
                          secondId={item.service_id}
                          onChange={inputChangeHandler}
                          insert={parseInt(item.service_price) - parseInt(item.paid_amount)}
                          convertDigits
                          onlyNumber
                          maxValue={parseInt(item.service_price) - parseInt(item.paid_amount)}
                        />
                      )}
                      {parseInt(item.service_price) == parseInt(item.paid_amount) && (
                        <View
                          style={{
                            height: 50,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontFamily: "IranSans-Regular", color: Colors.green }}>
                            تسویه شده
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                ))}
              </View>
            )}
          {formState.values.project && !formState.values.pay_link && (
            <View style={{ flexDirection: "row-reverse", marginTop: 20 }}>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, marginLeft: 10 }}>
                افزودن آیتم جدید به پروژه
              </Text>
              <Switch
                trackColor={{ false: "#767577", true: "#767577" }}
                thumbColor={formState.values.new_invoice ? Colors.primary : Colors.primary}
                onValueChange={() => {
                  dispatchFormState({ type: SET_ADD_TO_PROJECT })
                }}
                value={formState.values.add_to_project}
                style={{ marginHorizontal: 10, marginBottom: 10 }}
              />
            </View>
          )}
          {formState.values.add_to_project && !formState.values.pay_link && (
            <Picker
              label="آیتم های جانبی صورتحساب"
              id="invoice_extra_items"
              onChange={inputChangeHandler}
              options={
                formState.values.project.department == "site"
                  ? formState.values.services.extraSite
                  : formState.values.project.department == "graphic"
                  ? formState.values.services.extraGraphic
                  : formState.values.services.extraSales
              }
              isMulti
            />
          )}
          {formState.values.add_to_project &&
            formState.values.invoice_extra_items &&
            !formState.values.pay_link && (
              <View>
                {formState.values.invoice_extra_items.map((item, index) => (
                  <View style={{ flexDirection: "row-reverse" }} key={item.id}>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Text style={{ fontFamily: "IranSans-Regular", color: Colors.text }}>
                        {item.item}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Input
                        label="مبلغ"
                        id={"invoice_extra_items_price"}
                        secondId={item.id}
                        onChange={inputChangeHandler}
                        insert={get_extra_item_price(item.id) / 2}
                        convertDigits
                        onlyNumber
                        maxValue={get_extra_item_price(item.id)}
                      />
                    </View>
                  </View>
                ))}
              </View>
            )}
          {((formState.values.project &&
            formState.values.project.cost - formState.values.project.paid != 0) ||
            formState.values.new_invoice) &&
            !formState.values.pay_link && (
              <View>
                <View
                  style={{ width: "100%", height: 1, backgroundColor: "#e6e6e6", marginTop: 30 }}
                ></View>
                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    marginVertical: 30,
                  }}
                >
                  <Text
                    style={{ fontFamily: "IranSans-Regular", color: Colors.text, fontSize: 18 }}
                  >
                    مجموع فاکتور:{" "}
                  </Text>
                  <Text style={{ fontFamily: "IranSans-Medium", color: Colors.text, fontSize: 18 }}>
                    {separate(formState.values.total_invoice_price)} تومان
                  </Text>
                </View>
                <Btn
                  title="بروزرسانی قیمت کل"
                  onPress={() => {
                    calculate_total_amount()
                  }}
                  loading={formState.values.loading}
                  color={Colors.blue}
                />
                <View
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#e6e6e6",
                    marginVertical: 30,
                  }}
                ></View>
              </View>
            )}
          {formState.values.pay_link && (
            <TouchableOpacity
              onPress={() => {
                copyToClipboard(formState.values.pay_link)
              }}
              style={{
                width: "100%",
                height: 80,
                backgroundColor: Colors.green,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 7,
                marginTop: 40,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 14,
                  color: "#fff",
                  marginBottom: 5,
                }}
              >
                صورتحساب با موفقیت ساخته شد. لینک:
              </Text>
              <Text style={{ fontFamily: "IranSans-Regular", fontSize: 14, color: "#fff" }}>
                {formState.values.pay_link}
              </Text>
            </TouchableOpacity>
          )}

          {!formState.values.changed && !formState.values.pay_link && (
            <Btn
              title="ساخت صورتحساب"
              onPress={() => {
                if (formState.values.total_invoice_price == 0) {
                  alert("مبلغ صورتحساب صفر می باشد. چیزی اشتباه است!")
                } else {
                  make_invoice()
                }
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginVertical: 30 }}
            />
          )}
          {formState.values.pay_link && (
            <Btn
              title={project_id ? "بازگشت" : "ریست"}
              onPress={() => {
                if (project_id) {
                  props.navigation.goBack()
                } else {
                  dispatchFormState({ type: EVENT_BLUR })
                }
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginVertical: 30 }}
            />
          )}
        </ScrollView>
        <AwesomeAlert
          show={formState.values.showAlert}
          showProgress={false}
          title="اعلان"
          message={
            formState.values.alertMessage
              ? formState.values.alertMessage
              : "ابتدا خطاها را بررسی کنید!"
          }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="متوجه شدم"
          confirmButtonColor={Colors.green}
          onConfirmPressed={() => {
            dispatchFormState({ type: ALERT_SHOW, show: false })
          }}
          titleStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
          messageStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
          confirmButtonTextStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        />
      </View>
    </View>
  )
}

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default MakeInvoiceScreen
