export const isDevelopeMode =
  location.hostname.includes("localhost") || location.hostname.includes("violetonline")

export default {
  aioUrl: isDevelopeMode ? "https://dev.sogoods.ca/aio/v2" : "https://api.aminh.pro/aio/v2",
  appVersion: 183,
}

export const redirects = [
  {
    from: "/page/sitevideo",
    to: "https://aminh.pro/page/sitevideo",
  },
  {
    from: "/page/logovideo",
    to: "https://aminh.pro/page/logovideo",
  },
]
