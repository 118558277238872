import React, { useReducer, useCallback, useState } from "react"
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Dimensions } from "react-native"
import Colors from "../../constants/Colors"
import { separate } from "../../components/Functions"
import { useEffect } from "react"
import Config from "../../constants/Config"
import moment from "moment-jalaali"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Header from "../../components/Header"
import filter from "lodash.filter"
import Input from "../../components/Input"
import Btn from "../../components/Btn"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const SET_LOADING = "SET_LOADING"
const SET_TRANSACTIONS = "SET_TRANSACTIONS"
const SET_FUNDS = "SET_FUNDS"
const SET_ACTIVE_FUND = "SET_ACTIVE_FUND"
const SEARCH = "SEARCH"
const SET_FUNDS_VISIBILITY = "SET_FUNDS_VISIBILITY"
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: { ...state.values, loading: action.loading },
      }
    case SET_TRANSACTIONS:
      return {
        ...state,
        values: {
          ...state.values,
          transactions: action.data,
        },
      }
    case SET_FUNDS:
      return {
        ...state,
        values: {
          ...state.values,
          funds: action.data,
        },
      }
    case SET_ACTIVE_FUND:
      let active_fund = null
      if (state.values.active_fund != action.data) active_fund = action.data
      return {
        ...state,
        values: {
          ...state.values,
          active_fund: active_fund,
        },
      }
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          filtered_transactions: action.data,
          query: action.query,
        },
      }
    case SET_FUNDS_VISIBILITY:
      return {
        ...state,
        values: { ...state.values, funds_visibility: action.value },
      }
    default:
      return state
  }
}

const Transaction = (props) => {
  const { item, token } = props

  const method = () => {
    let result = ""
    if (item.method == "online") {
      result += "آنلاین - "
      if (item.gate == "melli") {
        result += "سداد"
      }
      if (item.gate == "zarin") {
        result += "زرین پال"
      }
    } else {
      if (item.gate == "cbc") result += "کارت به کارت"
      if (item.gate == "paya") result += "پایا"
      if (item.gate == "cash") result += "نقدی"
      if (item.gate == "zarinlink") result += "زرین لینک"
    }
    return result
  }

  const [download, setDownload] = useState(false)
  const get_file = async () => {
    const url = Config.aioUrl + "/transaction/getfile?normal=1&id=" + item.file_id
    setDownload(true)
    const file = await axios(url, {
      headers: { Authorization: "Bearer " + token },
      method: "get",
      responseType: "blob",
    })
    const blobUrl = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement("a")
    link.href = blobUrl
    link.setAttribute("download", "transaction_" + item.id + ".jpg")
    document.body.appendChild(link)
    link.click()
    setDownload(false)
  }

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        paddingVertical: 20,
        paddingHorizontal: 20,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 5 }}>
        {item.file_id && (
          <TouchableOpacity
            style={{
              width: 95,
              height: 25,
              backgroundColor: Colors.blue,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => {
              if (!download) get_file()
            }}
          >
            {!download && (
              <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>
                رسید
              </Text>
            )}
            {download && <ActivityIndicator size="small" color="#fff" />}
          </TouchableOpacity>
        )}
        {item.fund_title && (
          <View
            style={{
              width: 95,
              height: 25,
              backgroundColor: Colors.error,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Text style={{ fontFamily: "IranSans-regular", fontSize: 9, color: "#fff" }}>
              {item.fund_title}
            </Text>
          </View>
        )}

        <View
          style={{
            width: 95,
            height: 25,
            backgroundColor: Colors.secondary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>
            {moment.unix(item.date).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>
      </View>
      <View style={{ flex: 5 }}>
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 12,
            color: item.income == "1" ? Colors.green : Colors.primary,
            textAlign: "right",
          }}
        >
          {separate(item.amount)}
        </Text>
        <Text
          style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7 }}
        >
          {item.title ? item.title : item.order_id ? "صورتحساب #" + item.order_id : ""}
        </Text>
        <Text
          style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7 }}
        >
          {method()}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            transform: [{ rotate: "-90deg" }],
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          #{item.id}
        </Text>
      </View>
    </View>
  )
}

const TransactionsScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      transactions: [],
      funds: [],
      active_fund: null,
      active_type: "both",
      filtered_transactions: [],
      funds_visibility: true,
    },
  })

  const get_transactions = async (filters) => {
    let url = Config.aioUrl + "/transactions/get?type=" + filters.type + "&fund=" + filters.fund
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_TRANSACTIONS, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_funds = async () => {
    let url = Config.aioUrl + "/transactions/getFunds"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_FUNDS, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const handleSearch = useCallback(
    (id, text) => {
      try {
        const contains = (item, query) => {
          try {
            if (
              item?.id?.includes(query) ||
              (item.title ? item?.title?.includes(query) : false) ||
              `${item?.amount}`?.includes(query)
            ) {
              return true
            }
          } catch (error) {
            console.log("error: ", error)
            console.log("item: ", item)
            console.log("text: ", text)
          }

          return false
        }

        const data = filter(formState.values.transactions, (item) => contains(item, text))
        dispatchFormState({ type: SEARCH, data, query: text })
      } catch (error) {
        alert("مشکلی رخ داده است، مجددا تلاش کنید")
        console.log(error)
        console.log(text)
      }
    },
    [dispatchFormState, formState.values.transactions]
  )

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_transactions({ type: "both", fund: "all" })
      get_funds()
    })

    return focused
  }, [props.navigation])
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="تراکنش ها" noBack />
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      {!formState.values.loading && (
        <View style={{ height: "100%", flex: 1, paddingHorizontal: DESKTOP ? "20%" : 0 }}>
          {formState.values.funds_visibility && (
            <View style={{ marginBottom: 20 }}>
              {formState.values.funds.length != 0 && (
                <FlatList
                  numColumns={DESKTOP ? 3 : 2}
                  data={formState.values.funds}
                  keyExtractor={(item) => item.id}
                  renderItem={(itemData) => (
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        flexDirection: "row-reverse",
                        backgroundColor:
                          formState.values.active_fund == itemData.item.id
                            ? Colors.primary
                            : "#F2F2F2",
                        borderRadius: 15,
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        marginHorizontal: 5,
                        marginBottom: 10,
                      }}
                      onPress={() => {
                        dispatchFormState({ type: SET_ACTIVE_FUND, data: itemData.item.id })
                        dispatchFormState({ type: SET_FUNDS_VISIBILITY, value: false })
                        get_transactions({
                          type: formState.values.active_type,
                          fund:
                            formState.values.active_fund == itemData.item.id
                              ? "all"
                              : itemData.item.id,
                        })
                      }}
                    >
                      <View style={{ alignItems: "center", width: "100%" }}>
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 14,
                            color:
                              formState.values.active_fund == itemData.item.id
                                ? "#fff"
                                : Colors.text,
                          }}
                        >
                          {itemData.item.title}
                        </Text>
                        <View
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              fontSize: 12,
                              color:
                                formState.values.active_fund == itemData.item.id
                                  ? "#fff"
                                  : Colors.text,
                            }}
                          >
                            {"ظرفیت: "}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              fontSize: 12,
                              color:
                                formState.values.active_fund == itemData.item.id
                                  ? "#fff"
                                  : Colors.text,
                            }}
                          >
                            {separate(itemData.item.capacity) +
                              " / " +
                              separate(itemData.item.spent)}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            marginTop: 5,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              fontSize: 12,
                              color:
                                formState.values.active_fund == itemData.item.id
                                  ? "#fff"
                                  : Colors.text,
                            }}
                          >
                            {"مانده: "}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              fontSize: 12,
                              color:
                                formState.values.active_fund == itemData.item.id
                                  ? "#fff"
                                  : Colors.text,
                            }}
                          >
                            {separate(
                              parseInt(itemData.item.capacity) - parseInt(itemData.item.spent)
                            )}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )}
                />
              )}
            </View>
          )}
          {formState.values.funds.length > 2 && (
            <Btn
              title={formState.values.funds_visibility ? "بستن صندوق ها" : "نمایش صندوق ها"}
              color={Colors.secondText}
              onPress={() => {
                dispatchFormState({
                  type: SET_FUNDS_VISIBILITY,
                  value: formState.values.funds_visibility ? false : true,
                })
              }}
              wrapperStyle={{ marginBottom: 10 }}
            />
          )}
          <Input label="به دنبال چه می گردی؟" id="search" onChange={handleSearch} />
          <FlatList
            style={{ paddingBottom: 50 }}
            showsVerticalScrollIndicator={false}
            data={formState.values.filtered_transactions}
            keyExtractor={(item) => item.id}
            renderItem={(itemData, index) => <Transaction item={itemData.item} token={token} />}
          />
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default TransactionsScreen
